<template>
	<nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
		<div class="row w-100 mx-0 align-items-center">
			<div class="col-md col-sm col">
				<ul class="navbar-nav my-0 align-items-center content-width flex-row href" @click="goTo('/')">
					<img src="@/assets/images/icon.png" width="35" height="35" class="rounded-2 d-inline-block mx-2">
					<div class="px-1 navbar-brand">US Driving</div>
				</ul>
			</div>

			<div class="col-md col-sm col">
				<ul class="mb-0 d-flex justify-content-end" v-if="!isSignin()">
					<button type="button" style="margin-right: 8px" class="btn btn-outline-secondary my-sm-0 navbar-button" @click="goTo('/signin')">Sign In</button>
					<!-- <button type="button" class="btn btn-outline-secondary my-sm-0 mx-2" style="margin-left:5px" @click="goTo('/signup')">Signup</button> -->
				</ul>
				
				<ul class="mb-0 d-flex justify-content-end" v-else>
					<div style="margin-right: 8px" class="dropdown">
						<button class="btn btn-outline-secondary dropdown-toggle no-hover navbar-button" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Menus
						</button>
						<div style="right: 19px; position: fixed;" class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton">
							<div class="dropdown-item href" @click="goTo('/account')">Account</div>
							<div class="dropdown-item href" @click="goTo('/quiz/history/1')">Quiz History</div>
							<div class="dropdown-item href" @click="toggleContact()">Contact Us</div>
							<div class="dropdown-item href" @click="logout('/')">Logout</div>
						</div>
					</div>
				</ul>
			</div>
		</div>
	</nav>
</template>

<script>
	import functions from "@/mixin/functions"

	export default {
		name: "Navbar",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		mounted(){
			if(!this.validateUser())
				this.logout('/invalid-signin')
		},
		methods: {
			toggleContact() {
				window.location.href = 'mailto:support@usdriving.us'
			}
		},
	}
</script>