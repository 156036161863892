<template>
	
	<Loading v-if="loading" />

	<div class="vertical-center mx-10p" :hidden="loading">
        <div class="row">
            <div class="col-9 align-self-center">
                <p card-text class="display-2 text-light"><b>Quiz Detail</b></p>
            </div>
        </div>
        <div id="content">
			
			<div class="mx-2 mb-2">
				<div class="row mt-1">
					<div class="col-md-6 text-light question-stem">
						Name: {{username}}
					</div>
					<div class="col-md-6 text-light question-stem">
						Submit Time: {{new Date(parseInt(submit_time)).toLocaleString('en-US', { hourCycle: 'h23' })}}
					</div>
				</div>
				<div class="row">
					<div class="col-md-6 text-light mt-1 question-stem">
						Score: {{score}}/100
					</div>
					<div class="col-md-6 text-light mt-1 question-stem">
						{{score > 80 ? 'You passed the quiz!' : 'You did not pass the quiz!'}}
					</div>
				</div>
			</div>
		
			<div class="mx-2" v-for="(i, index) in questions">
				<div style="height: 1px; width: 100%; background-color: white; margin: auto; margin-bottom: 0.5rem"></div>
                <div class="row">
                    <div class="col-12 align-self-center">
                        <p class="text-light question-stem mt-0">
                            {{index + 1}}. {{questions_body[i].stem}} 
                        </p>
                    </div>
                </div>
				
				<div class="row">
					<div class="mx-15p text-center my-2" v-if="questions_body[i].image != '' && mobile">
						<img class="question-image-mobile" v-bind:src="questions_body[i].image">
					</div>
				</div>
				
				<div class="row">
                    <div class="col-10 align-self-center" v-bind:class="{ 'col-12' : questions_body[i].image == '' }">
                        <p class="text-light lead" v-bind:class="{ 'result-correct' : questions_body[i].answer == 'A', 'result-wrong' : user_answers[index] == 'A' && questions_body[i].answer != 'A' }">
                            A. {{questions_body[i].A}}
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'result-correct' : questions_body[i].answer == 'B', 'result-wrong' : user_answers[index] == 'B' && questions_body[i].answer != 'B' }">
                            B. {{questions_body[i].B}}
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'd-none' : questions_body[i].C == '', 'result-correct' : questions_body[i].answer == 'C', 'result-wrong' : user_answers[index] == 'C' && questions_body[i].answer != 'C' }">
                            C. {{questions_body[i].C}}
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'd-none' : questions_body[i].D == '', 'result-correct' : questions_body[i].answer == 'D', 'result-wrong' : user_answers[index] == 'D' && questions_body[i].answer != 'D' }">
                            D. {{questions_body[i].D}}
                        </p>

                    </div>

                    <div class="col-2 align-self-center" v-if="questions_body[i].image != '' && !mobile">
                        <img class="question-image" v-bind:src="questions_body[i].image">
                    </div>
				</div>
				
				<div class="row">
					<p class="text-light lead result-text" v-if="user_answers[index] == 'X'">
							<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="orange" class="bi bi-x d-inline" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
						You did not answer this question
					</p>
					<p class="text-light lead result-text" v-else-if="user_answers[index] == questions_body[i].answer">
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="green" class="bi bi-check" viewBox="0 0 16 16"><path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path></svg>
						You did great
					</p>
					<p class="text-light lead result-text" v-else>
						<td>
							<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="red" class="bi bi-x d-inline" viewBox="0 0 16 16"><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path></svg>
							Your Answered: {{user_answers[index]}}
						</td>
						<td class="ps-5">
							Correct Answer: {{questions_body[i].answer}}
						</td>
					</p>
				</div>

            </div>
			
			<div class="row" v-if="false">
                <div class="col-9">
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goToQuestion(question_num - 1)" :disabled="question_num == 1">Previous</button>
					<input class="form-control page-input quiz-button mx-2" type="num" id="page" name="page" placeholder="Jump To" v-on:keyup.enter="togglePage()" >
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goToQuestion(question_num + 1)" :disabled="question_num == questions.length">Next</button>
                </div>
            </div>
			
			
        </div>
    </div>

</template>

<style>
	
</style>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	export default {
		name: "QuizDetail",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		created(){
			if(!this.continue_lifecycle)
				return
			
			if(!this.isSignin()){
				this.continue_lifecycle = false
				this.goTo('/signin')
				return
			}
		
			this.uuid = this.$route.params.uuid
			
			axios.post(this.BASE_URL + "quiz", JSON.stringify({
				"action": "get",
				"lang": this.lang,
				"data":{
					"uuid": this.uuid
				}
			})).then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.questions = result['body']['quiz']['questions'].split(',')
					this.user_answers = result['body']['quiz']['user_answers'].split(',')
					this.submit_time = result['body']['quiz']['submit_time']
					this.score = result['body']['quiz']['score']
					this.questions_body = result['body']['questions_body']
				}
				this.loading = false
			})
			
		},
		data() {
			return {
				continue_lifecycle: true,
				uuid: '',
				questions: [],
				user_answers: [],
				submit_time: 0,
				score: 0,
				questions_body: {},
				question: {},
			}
		},
		methods: {
			goToQuestion(i) {
				this.goTo('/learn/' + i)
			},
			togglePage(){
				let page = $("#page").val()
				$("#page").val('')
				if(page >= 1 && page <= this.questions.length)
					this.goTo('/learn/' + page)
			},
			mark(){
				if(this.marked.includes(this.question_num)){
					this.marked.splice( this.marked.indexOf(this.question_num), 1)
					this.marked = this.marked.sort(function(a, b){return a-b})
					sessionStorage.marked = this.marked
					console.log(this.marked)
				}else{
					this.marked.push(this.question_num)
					this.marked = this.marked.sort(function(a, b){return a-b})
					sessionStorage.marked = this.marked
					console.log(this.marked)
				}
			},
		}
	}
</script>