<template>
	
	<Loading v-if="loading" />
	
	<div id="content-white" :hidden="loading">
		<h1 id="title" class="display-4 text-center mb-4">Account Info</h1>
		<form class="form-inline">
			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label>Full Name</label>
					<div id="name" class="text-dark fw-bold fs-5 mt-1">Name</div>
					<div id="name-feedback" class="invalid-feedback mt-2" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label>State</label>
					<div id="area" class="text-dark fw-bold fs-5 mt-1">State</div>
					<div id="area-feedback" class="invalid-feedback mt-2" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label>Phone</label>
					<div id="phone_number" class="text-dark fw-bold fs-5 mt-1">Phone</div>
					<div id="phone_number-feedback" class="invalid-feedback mt-2" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label>Email</label>
					<div id="email_address" class="text-dark fw-bold fs-5 mt-1">Email</div>
					<div id="email_address-feedback" class="invalid-feedback mt-2" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div align="center" style="margin-top:0.75rem" v-bind:class="{ 'mb-2' : !mobile }">
				<div class="dropdown mt-2">
					<button class="btn btn-outline-secondary dropdown-toggle" style="width:160px" type="button" id="updateMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						Update
					</button>
					<div style="position: fixed;width:160px" class="dropdown-menu" aria-labelledby="updateMenuButton">
						<div class="dropdown-item dropdown-item-light href" @click="goTo('/update/username')" hidden>Username</div>
						<div class="dropdown-item dropdown-item-light href" @click="goTo('/update/account')">Profile</div>
						<div class="dropdown-item dropdown-item-light href" @click="goTo('/update/password')">Password</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')

	export default {
		name: "Account",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		data() {
			return {
				account_info: {},
				input_array: ["name", "area", "phone_number", "email_address"],
			}
		},
		created(){
			if(!this.isSignin()){
				this.goTo('/signin')
				return
			}
			
			axios.post(this.BASE_URL + "user", JSON.stringify({
				"action": "get",
				"data":{
					"username": this.username
				}
			}))
			.then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.account_info = result['body']
					console.log('account_info', this.account_info)
					this.fillInfo()
					this.loading = false
				}
			})
		},
		methods: {
			fillInfo(){
				console.log(this.account_info)
				$("#title").text(this.account_info['username'])
				for(let input of this.input_array)
					$("#" + input).text(this.account_info[input])
			},
		}
	}
</script>