<template>
	
	<Loading v-if="loading" />

    <div class="vertical-center mx-15p" :hidden="loading">
        <p card-text class="display-2 text-light"><b>Select Category</b></p>
        <div id="content">
            <div class="row mx-5 mt-4">
                <div class="col-md-6">
                    <p class="text-light my-4 h3 text-center">
                        Category
                    </p>
                </div>

                <div class="col-md-6">
					<select class="form-select my-4 w-50 mx-auto" style="min-width: 170px; max-width: 250px; border-radius: 20px" id="category">
						<option selected>All</option>
						<option v-for="item in categories">{{item}}</option>
					</select>
                </div>
            </div>
			
			
            <div class="row mx-5 mt-5">
                <div class="col-md-6">
                    <p class="text-light my-4 h3 text-center">
                        Sort Order
                    </p>
                </div>

                <div class="col-md-6">
					<div style="width: fit-content; margin: auto">
						<div class="my-3">
							<input class="radio-button" type="radio" id="in-order" @change="toggleOrder('in-order')" checked>
							<label for="in-order" class="text-light mx-1">In Order</label>
						</div>
						<div class="my-3">
							<input class="radio-button" type="radio" id="out-of-order" @change="toggleOrder('out-of-order')">
							<label for="out-of-order" class="text-light mx-1">Out of Order</label>
						</div>
					</div>
                </div>
            </div>
            
            <div class="text-center mt-4">
                <button class="btn btn-outline-light select-button my-4 bi-bookmark-fill" style="width:auto" hidden>&nbsp; Marked</button>
                <button class="btn btn-outline-light select-button my-4" v-bind:class="{ 'select-button-computer' : !mobile }"  @click="toggleLearn()">Start Learn</button>
                <button class="btn btn-outline-light select-button my-4" v-bind:class="{ 'select-button-computer' : !mobile }" @click="toggleQuiz()">Start Test</button>
            </div>
            
        </div>
    </div>
</template>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	export default {
		name: "Category",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		data() {
			return {
				categories: [],
			}
		},
		created(){
			if(!this.isSignin()){
				this.goTo('/signin')
				return
			}

			axios.post(this.BASE_URL + "question", JSON.stringify({
				"action": "category_list"
			}))
			.then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.categories = result['body']
					console.log('categories', this.categories)
					this.loading = false
				}
			})
		},
		methods: {
			toggleLearn() {
				let category = $("#category").val()
				if($("#in-order").prop("checked"))
					this.startLearn(category, false)
				else
					this.startLearn(category, true)
			},
			toggleQuiz(){
				let category = $("#category").val()
				if($("#in-order").prop("checked"))
					this.startQuiz(category, false)
				else
					this.startQuiz(category, true)
			},
			clearOrder(){
				$("#in-order").prop("checked", false)
				$("#out-of-order").prop("checked", false)
			},
			toggleOrder(order){
				this.clearOrder()
				$("#" + order).prop("checked", true)
			}
		}
	}
</script>