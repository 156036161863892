<template>
	<div class="vertical-center mx-10p">
		<p class="display-2 text-light"><b>US Driving</b></p>
		<p class="h3 text-light my-2">Get Prepared for DMV Knowledge Test</p>
		<button type="button" class="btn btn-outline-light my-3" @click="goTo('/mode')">Get Started</button>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	
	export default {
		name: "Home",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
	}
</script>