<template>

	<Loading v-if="loading" />
	
	<div id="content-white" :hidden="loading">
		<h1 id="title" class="display-4 text-center mb-4">Account Info</h1>
		<form class="form-inline">
			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label id="name-label" for="name">Full Name</label>
					<input class="form-control"  type="text" id="name" name="name" placeholder="Full Name" v-on:keyup.enter="toggleUpdate()">
					<div id="name-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label id="area-label" for="area">State</label>
					<select class="form-control" id="area" name="area">
						<option hidden>State</option>
						<option v-for="area in states">{{area}}</option>
					</select>
					<div id="area-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label id="phone_number-label" for="phone_number">Phone</label>
					<input class="form-control"  type="phone" id="phone_number" name="phone_number" placeholder="Phone" v-on:keyup.enter="toggleUpdate()">
					<div id="phone_number-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label id="email_address-label" for="email_address">Email</label>
					<input class="form-control"  type="email" id="email_address" name="email_address" placeholder="Email" v-on:keyup.enter="toggleUpdate()">
					<div id="email_address-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div align="center" class="mt-1" v-bind:class="{ 'mb-2' : !mobile }">
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="toggleUpdate()">Update</button>
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="resetInput();clearError()">Reset</button>
			</div>
		</form>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	export default {
		name: "UpdateAccount",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		data() {
			return {
				account_info: {},
				input_array: ["name", "area", "phone_number", "email_address"],
				states: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
			}
		},
		created(){
			if(!this.isSignin()){
				this.goTo('/signin')
				return
			}
			
			axios.post(this.BASE_URL + "user", JSON.stringify({
				"action": "get",
				"data":{
					"username": this.username
				}
			}))
			.then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.account_info = result['body']
					console.log('account_info', this.account_info)
					this.clearError()
					this.resetInput()
					this.loading = false
				}
			})
		},
		methods: {
			resetInput(){
				console.log(this.account_info)
				$("#title").text(this.account_info['username'])
				for(let input of this.input_array)
					$("#" + input).val(this.account_info[input])
			},
			clearError(){
				for(let input of this.input_array){
					$("#" + input + "-label").removeClass('text-danger')
					$("#" + input + "-feedback").removeClass('d-block')
					$("#" + input + "-feedback").text('')
					$("#" + input).removeClass('invalid-input')
				}
			},
			checkName(name){
				if(name == "" || name == null || name == undefined)
					return [false, 'Please enter name.']
				return [true]
			},
			checkState(area){
				if(!this.states.includes(area))
					return [false, 'Please select state.']
				return [true]
			},
			checkPhone(phone_number){
				if(phone_number == "" || phone_number == null || phone_number == undefined)
					return [false, 'Please enter phone number.']
				return [true]
			},
			checkEmail(email_address){
				if(email_address == "" || email_address == null || email_address == undefined)
					return [false, 'Please enter email address.']
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email_address)){
					return [true]
				}
				return [false, "Invalid email address."]
			},
			checkInput(input, value){
				if(input == 'name')
					return this.checkName(value)
				if(input == 'phone_number')
					return this.checkPhone(value)
				if(input == 'email_address')
					return this.checkEmail(value)
				if(input == 'area')
					return this.checkState(value)
			},
			async toggleUpdate() {
				this.clearError()
				
				let name = $("#name").val()
				let phone_number = $("#phone_number").val()
				let email_address = $("#email_address").val()
				let area = $("#area").val()
				console.log("update", name, phone_number, email_address, area)
				
				let valid = true
				for(let input of this.input_array){
					let [result, msg] = this.checkInput(input, $("#" + input).val())
					if(!result){
						$("#" + input + "-label").addClass('text-danger')
						$("#" + input + "-feedback").addClass('d-block')
						$("#" + input + "-feedback").text(msg)
						$("#" + input + "").addClass('invalid-input')
						valid = false
					}
				}
				
				if(!valid)
					return
				
				axios.post(this.BASE_URL + "user", JSON.stringify({
					"action": "update",
					"data":{
						"username": this.username,
						"name": name,
						"phone_number": phone_number,
						"email_address": email_address,
						"area": area,
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					this.goTo('/account')
				})
			},
		}
	}
</script>