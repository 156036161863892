<template>
	<div id="content-white">
		<h1 id="title" class="display-4 text-center mb-4">Forgot Password</h1>
		<form class="form-inline">
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="username-label" for="username">Username</label>
					<input class="form-control" type="text" id="username" name="username" placeholder="Username" v-bind:disabled="sent">
					<div id="username-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" v-if="!sent">
				<div class="col-md-12">
					<label id="code-label" for="email_address">Email</label>
					<div class="input-group">
						<input class="form-control"  type="text" id="email_address" name="email_address" placeholder="Email" v-on:keyup.enter="toggleUpdate()">
						<button type="button" id="send-button" class="btn btn-outline-secondary send-button" @click="sendCode()">Send Code</button>
					</div>
					<div id="email_address-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" v-else>
				<div class="col-md-12">
					<label id="code-label" for="code">Verification Code</label>
					<div class="input-group">
						<input class="form-control"  type="text" id="code" name="code" placeholder="Verification Code" v-on:keyup.enter="toggleUpdate()">
						<button type="button" id="resend-button" class="btn btn-outline-secondary send-button" @click="sendCode(true)" disabled>{{countdown}}</button>
					</div>
					<div id="code-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="new_password-label" for="new_password">New Password</label>
					<input class="form-control"  type="password" id="new_password" name="new_password" placeholder="New Password" v-on:keyup.enter="toggleUpdate()">
					<div id="new_password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="repeat_password-label" for="repeat_password">Repeat Password</label>
					<input class="form-control"  type="password" id="repeat_password" name="repeat_password" placeholder="Repeat Password" v-on:keyup.enter="toggleUpdate()">
					<div id="repeat_password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div align="center" class="mt-1" v-bind:class="{ 'mb-2' : !mobile }">
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="toggleUpdate()">Update</button>
			</div>
		</form>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	
	const axios = require('axios')

	let timeoutID = 0
	
	export default {
		name: "ResetPassword",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		data() {
			return {
				sent: false,
				initial_countdown: 5,
				countdown: 'Resend',
				input_array: ["username", "email_address", "code", "new_password", "repeat_password"],
			}
		},
		created(){
			if(this.isSignin()){
				this.goTo('/')
				return
			}
		},
		mounted(){
			this.clearError()
			this.clearInput()
		},
		unmounted(){
			clearTimeout(timeoutID)
		},
		methods: {
			timer(){
				if(this.countdown == 1){
					this.countdown = 'Resend'
					$("#resend-button").prop("disabled", false);
					return
				}
				this.countdown -= 1
				timeoutID = setTimeout(this.timer, 1000)
			},
			clearInput(){
				for(let input of this.input_array)
					$("#" + input).val('')
			},
			clearError(){
				for(let input of this.input_array){
					$("#" + input + "-label").removeClass('text-danger')
					$("#" + input + "-feedback").removeClass('d-block')
					$("#" + input + "-feedback").text('')
					$("#" + input).removeClass('invalid-input')
				}
			},
			checkUsername(username){
				if(username == "" || username == null || username == undefined)
					return [false, 'Please enter username.']
				return [true]
			},
			checkCode(code){
				if(code == "" || code == null || code == undefined)
					return [false, 'Please enter code.']
				return [true]
			},
			checkEmail(email_address){
				if(email_address == "" || email_address == null || email_address == undefined)
					return [false, 'Please enter email address.']
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email_address)){
					return [true]
				}
				return [false, "Invalid email address."]
			},
			checkPassword(password){
				if(password == "" || password == null || password == undefined)
					return [false, 'Please enter password.']
				return [true]
			},
			checkRepeatPassword(new_password, repeat_password){
				if(new_password != repeat_password)
					return [false, 'Password mismatch.']
				return [true]
			},
			checkInput(input, value){
				if(input == 'username')
					return this.checkUsername(value)
				if(input == 'email_address')
					return this.checkEmail(value)
				if(input == 'code')
					return this.checkCode(value)
				if(input == 'new_password')
					return this.checkPassword(value)
				if(input == 'repeat_password')
					return this.checkPassword(value)
			},
			setError(input, msg){
				$("#" + input + "-label").addClass('text-danger')
				$("#" + input + "-feedback").addClass('d-block')
				$("#" + input + "-feedback").text(msg)
				$("#" + input + "").addClass('invalid-input')
			},
			async sendCode(force=false){
				this.clearError()


				let valid = true

				this.username = $('#username').val()
				let [result, msg] = this.checkUsername(this.username)
				if(!result){
					this.setError('username', msg)
					valid = false
				}

				if(!force){
					this.email_address = $('#email_address').val();
					[result, msg] = this.checkEmail(this.email_address)
					if(!result){
						this.setError('email_address', msg)
						valid = false
					}
				}

				if(!valid)
					return
				
				axios.post(this.BASE_URL + "email", JSON.stringify({
					"action": "send_code_to_user",
					"data": {
						"username": this.username,
						"to_address": this.email_address
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] == 200){
						this.countdown = this.initial_countdown
						this.sent = true
						timeoutID = setTimeout(this.timer, 1000)
					}else if(!force){
						this.setError('email_address', result['body'])
					}else{
						this.setError('code', result['body'])
					}
				})
			},
			async toggleUpdate() {
				this.clearError()
				
				let username = $("#username").val()
				let code = $("#code").val()
				let new_password = $("#new_password").val()
				let repeat_password = $("#repeat_password").val()
				console.log("update", username, code, new_password, repeat_password)
				
				let valid = true
				for(let input of this.input_array){
					if(input == 'email_address')
						continue
					let [result, msg] = this.checkInput(input, $("#" + input).val())
					if(!result){
						this.setError(input, msg)
						valid = false
					}
				}

				let [result, msg] = this.checkRepeatPassword(new_password, repeat_password)
				if(!result){
					this.setError('new_password', msg)
					this.setError('repeat_password', msg)
					valid = false
				}

				if(!valid)
					return
				
				axios.post(this.BASE_URL + "user", JSON.stringify({
					"action": "reset_password",
					"encode": true,
					"data":{
						"username": username,
						"verification_code": code,
						"new_password": this.encode_password(new_password)
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] == 200){
						let email_address = result['body']['email_address']
						this.loginUser(username, email_address)
						this.goTo('/')
					}else{
						console.log('signin failed')
						this.setError('repeat_password', result['body'])
					}
				})
			},
		}
	}
</script>