import { createRouter, createWebHistory } from "vue-router"
import Home from "../views/Home.vue"
import Signin from "../views/Signin.vue"
import Signup from "../views/Signup.vue"
import Account from "../views/Account.vue"
import UpdateAccount from "../views/UpdateAccount.vue"
import UpdatePassword from "../views/UpdatePassword.vue"
import ResetPassword from "../views/ResetPassword.vue"
import Mode from "../views/Mode.vue"
import Category from "../views/Category.vue"
import Quiz from "../views/Quiz.vue"
import Learn from "../views/Learn.vue"
import QuizHistory from "../views/QuizHistory.vue"
import QuizDetail from "../views/QuizDetail.vue"
import Error from "../views/Error.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup/:third_party?",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/update/account",
    name: "UpdateAccount",
    component: UpdateAccount,
  },
  {
    path: "/update/password",
    name: "UpdatePassword",
    component: UpdatePassword,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/mode",
    name: "Mode",
    component: Mode,
  },
  {
    path: "/category",
    name: "Category",
    component: Category,
  },
  {
    path: "/quiz/:num",
    name: "Quiz",
    component: Quiz,
  },
  {
    path: "/learn/:num",
    name: "Learn",
    component: Learn,
  },
  {
    path: "/quiz/history/:page",
    name: "QuizHistory",
    component: QuizHistory,
  },
  {
    path: "/quiz/detail/:uuid",
    name: "QuizDetail",
    component: QuizDetail,
  },
  {
    path: "/:catchAll(.*)",
    name: "404",
    component: Error,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
