<template>
	<div id="background"></div>
	<Navbar :key="$route.fullPath + refresh_key" @incrementKey="incrementKey" :refresh_key="refresh_key" />
	<router-view :key="$route.fullPath + refresh_key" @incrementKey="incrementKey" :refresh_key="refresh_key" />
</template>

<script>
	import functions from "@/mixin/functions"
	import Navbar from "@/components/Navbar.vue"
	
	export default {
		mixins: [functions],
		components: {
			Navbar,
		},
		data() {
			return {
				refresh_key: 0
			}
		},
		methods: {
			incrementKey(){
				this.refresh_key ++
			},
		},
	}
</script>

<style type="text/css">
	@font-face {
		font-family: candara;
		src: url("@/assets/fonts/Candara.ttf");
	}

	body{
		font-family: candara;
	}
	
	#content-white{
		background-color: rgba(255,255,255,.9);
		padding: 30px;
		border-radius: 1.5em;
		box-shadow: 0px 11px 35px 2px #000000;
		width: 40%;
		min-width: min(500px, 90vw);
		max-width: min(600px, 90vw);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -47%);
	}
	
	#content{
		height: auto;
		max-height: calc(85vh - 150px);
		background: rgba(0,0,0,.6);
		padding-left: 10px;
		padding-right: 10px;
		overflow: auto
	}

	#content button{
		align-content: center;
		place-items: center;
		display: inline-flex;
		margin-inline: 0.2rem;
		margin-top: 0.2rem;
		margin-bottom: 0.2rem;
		width: 40px;
		height: 40px;
	}

	#background {
		background-image: url("@/assets/images/road.jpeg");
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
		position:fixed;
		top:0;
		left:0;
		z-index:-1;
	}
	
	.vertical-center{
		margin: 0;
		position: absolute;
		top: 50%;
		-ms-transform: translateY(-50%);
		transform: translateY(-47%);
	}

	/* .horizontal-center{
		width: 100%;
		margin-left: auto;
		margin-right: auto;
	} */

	.mx-10p{
		width: 80%;
		margin-left: 10%;
		margin-right: 10%;
	}

	.mx-15p{
		width: 70%;
		margin-left: 15%;
		margin-right: 15%;
	}
	
	.option-label{
		margin-left: calc(0rem + 0.3vw);
		display: inline
	}

	.href{
		text-decoration: none;
		cursor: pointer;
	}

	.href-link{
		text-decoration: underline;
		color: #6c757d;
	}

	.content-width{
		width: fit-content;
	}

	.result-wrong {
		color: #ff7f7f !important;
		font-weight: bold !important;
	}

	.result-correct {
		color: #7fffd4 !important;
		font-weight: bold !important;
	}

	.result-text {
		font-weight: bold !important;
		margin-bottom: 3rem;
	}

	#timer{
		float: right;
		margin-right: 10px;
	}
	
	.marked-p{
		float: right;
		margin-right: 10px;
		margin-bottom: 0;
	}

	.marked-p:hover{
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
		transition: ease 0.3s;
	}

	.navbar-button{
		width: 90px !important
	}
	
	.quiz-button{
		width: 80px !important;
		place-content: center;
	}
	
	.quiz-num-button{
		padding-left: 0;
		padding-right: 0;
		width: 32px !important;
		place-content: center;
		text-align: center;
	}
	
	.num-button{
		display: inline;
		place-content: center;
		min-width: 40px;
	}
	
	.select-button{
		width: 110px !important;
		align-items: center;
		justify-content: center;
		margin-left: 10px !important;
		margin-right: 10px !important;
	}
	
	.select-button-computer{
		margin-left: 50px !important;
		margin-right: 50px !important;
	}
	
	.send-button{
		width: 100px
	}
	
	.ls-button{
		width: 90px
	}
	
	.question-image{
		max-height: 185px;
		float: right;
		display:inline;
		max-width:100%;
	}
	
	.question-image-mobile{
		max-height: 185px;
		max-width: 35vw !important;
	}
	
	.page-input{
		width: 85px;
		place-content: cener;
		display: inline
	}

	.three-options{
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.two-options{
		margin-top: 2rem;
		margin-bottom: 2.5rem;
	}
	
	.quiz-detail-button{
		text-decoration: underline;
		cursor: pointer;
	}

	.mode-image{
		width: 20%;
		min-width: 9rem;
		max-width: 16rem;
		margin-left: 5%;
		margin-right: 5%;
		transition: ease 0.5s;
	}

	.mode-image:hover {
		opacity: 0.9;
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
		transition: ease 1s;
	}

	.mark-icon{
		font-family: bootstrap-icons !important;
	}

	.mark-icon::before{
		content:  "\f199";
	}
	
	.radio-button { 
		transform: scale(1.1);
		margin-left: 2px;
		margin-right: 5px;
		accent-color: grey;
	}
	
	.dropdown-item:hover{
		background-color: #495057 !important;
		transition: ease 0.2s;
	}
	
	.dropdown-item:active{
		background-color: #6c757d !important;
		transition: ease 0.2s;
	}
	
	.dropdown-item-light:hover{
		background-color: #dee2e6 !important;
		transition: ease 0.2s;
	}
	
	.dropdown-item-light:active{
		background-color: #6c757d !important;
		transition: ease 0.2s;
	}
	
	.btn:hover{
		-webkit-transform: scale(1.1);
		-ms-transform: scale(1.1);
		transform: scale(1.1);
		transition: ease 0.3s;
	}
	
	.no-hover:hover{
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		transition: ease 0.2s;
	}
	
	.invalid-input{
		border-color: #dc3545;
		padding-right: calc(1.5em + 0.75rem);
		background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e);
		background-repeat: no-repeat;
		background-position: right calc(0.375em + 0.1875rem) center;
		background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
	}
	
	.invalid-input:focus {
        border-color: #dc3545;
        box-shadow: 0 0 0 4px rgba(220, 53, 69, 0.25);
	}
	
	.invalid-feedback {
		height: 1rem;
		display: block;
	}
	
	.question-nums-row{
		max-height: 49px;
		overflow: auto;
	}
	
	label{
		font-size: calc(1rem + 0.1vw);
	}
	
	.display-2{
		font-size: calc(1.3rem + 3vw);
		font-weight: 300;
		line-height: 1.2;
	}
	
	.question-stem{
		font-size: calc(1.2rem + 0.6vw);
		margin-top: calc(0.4rem + 0.6vw);
		margin-bottom: calc(0.1rem + 0.3vw);
	}
	
	.lead{
		font-size: calc(1rem + 0.4vw);
		margin-top: calc(0.2rem + 0.2vw);
		margin-bottom: calc(0.2rem + 0.2vw);
	}
	
	.lead label{
		font-size: calc(1.1rem + 0.2vw);
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}
	
	/* width */
	::-webkit-scrollbar {
		width: 10px;
		height: 5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #dddddd;
		border-radius: 20px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888888;
		border-radius: 20px;       /* roundness of the scroll thumb */
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555555;
	}
</style>