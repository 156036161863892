<template>
	
	<Loading v-if="loading" />
	
	<div class="vertical-center mx-10p" :hidden="loading">
        <div class="row">
            <div class="col-10 align-self-center">
                <p card-text class="display-2 text-light"><b>Quiz (80% to Pass)</b></p>
            </div>
            <div class="col-2 align-self-center">
                <h2 id="timer" class="text-light"></h2>
            </div>
        </div>
        <div id="content">
            <div id="question_nums" class="mt-2">
				<div class="question-nums-row" v-bind:class="{ 'd-flex' : mobile }">
					<button v-for="i in questions.length" class="btn btn-outline-light num-button" v-bind:class="{ 'mark-icon' : marked.includes(i) , 'btn-secondary' : user_answers[i - 1] != 'X' }" @click="goToQuestion(i)">{{ marked.includes(i) ? '' : i }}</button>
				</div>
            </div>
			
			<div class="mx-2">
                <div class="row">
                    <div class="col-12 align-self-center">
                        <p class="text-light question-stem">
                            {{question_num}}. {{question.stem}}
                        </p>
                    </div>
                </div>
				
				<div class="row">
					<div class="mx-15p text-center my-2" v-if="question.image != '' && mobile">
						<img class="question-image-mobile" v-bind:src="question.image">
					</div>
				</div>

				<div class="row">
                    <div class="col-10 align-self-center" v-bind:class="{ 'col-12' : question.image == '' || mobile }">
                        <p class="text-light lead" v-bind:class="{ 'three-options' : question.D == '', 'two-options' : question.C == '' && question.D == '' }">
                            <input class="radio-button" type="radio" id="A" value="A" @change="selectSingle($event)" :checked="user_answers[question_num - 1] == 'A'">
                            <label for="A" class="option-label"> {{question.A}}</label>
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'three-options' : question.D == '', 'two-options' : question.C == '' && question.D == '' }">
                            <input class="radio-button" type="radio" id="B" value="B" @change="selectSingle($event)" :checked="user_answers[question_num - 1] == 'B'">
                            <label for="B" class="option-label"> {{question.B}}</label>
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'three-options' : question.D == '', 'd-none' : question.C == '' }">
                            <input class="radio-button" type="radio" id="C" value="C" @change="selectSingle($event)" :checked="user_answers[question_num - 1] == 'C'">
                            <label for="C" class="option-label"> {{question.C}}</label>
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'd-none' : question.D == '' }">
                            <input class="radio-button" type="radio" id="D" value="D" @change="selectSingle($event)" :checked="user_answers[question_num - 1] == 'D'">
                            <label for="D" class="option-label"> {{question.D}}</label>
                        </p>
                    </div>

                    <div class="col-2 align-self-center" v-if="question.image != '' && !mobile">
                        <img class="question-image" v-bind:src="question.image">
                    </div>
				</div>
            </div>
			
			<div class="d-flex">
                <div class="col-9">
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goToQuestion(question_num - 1)" :disabled="question_num == 1">Previous</button>
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goToQuestion(question_num + 1)" :disabled="question_num == questions.length">Next</button>
                    <button class="btn btn-outline-light my-4 quiz-button" @click="submit()">Submit</button>
                </div>
                <div class="col-3 align-self-center">
                    <p class="marked-p"><a class="text-light href" v-bind:class="{ 'bi-bookmark' : !marked.includes(question_num), 'bi-bookmark-fill' : marked.includes(question_num) }" @click="mark()"></a></p>
                    <p class="marked-p mx-3"><a class="text-light href" @click="toggleLang()">{{ lang == 'en' ? 'EN' : '中' }}</a></p>
                </div>
            </div>
			
        </div>
    </div>

</template>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	let intervalId = 0
	let timeoutID = 0
	
	export default {
		name: "Quiz",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		updated(){
			console.log('updated')
		},
		created(){
			if(!this.continue_lifecycle)
				return
			
			if(!this.isSignin()){
				this.continue_lifecycle = false
				this.goTo('/signin')
				return
			}
			if(sessionStorage.uuid == undefined || sessionStorage.marked == undefined || sessionStorage.expire == undefined
			 || sessionStorage.questions == undefined || sessionStorage.user_answers == undefined){
				this.continue_lifecycle = false
				this.goTo('/')
				return
			}
			if(!this.validateQuiz()){
				this.continue_lifecycle = false
				return
			}
		
			this.question_num = parseInt(this.$route.params.num)
			this.uuid = sessionStorage.uuid
			this.marked = sessionStorage.marked
			this.expire = sessionStorage.expire
			this.questions = sessionStorage.questions.split(',')
			this.user_answers = sessionStorage.user_answers.split(',')
			if(this.marked == ''){
				this.marked = []
			}else if(this.marked.includes(',')){
				this.marked = this.marked.split(',')
				for(let i = 0; i < this.marked.length; i ++){
					this.marked[i] = parseInt(this.marked[i])
				}
			}else{
				this.marked = [parseInt(this.marked)]
			}
			
			console.log('question_num', this.question_num)
			console.log('questions', this.questions)
			console.log('user_answers', this.user_answers)
			console.log('marked', this.marked)
			console.log('expire', this.expire)
			
			axios.post(this.BASE_URL + "question", JSON.stringify({
				"action": "get",
				"lang": this.lang,
				"quiz_mode": true,
				"data":{
					"id": this.questions[this.question_num - 1]
				}
			}))
			.then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.question = result['body']
					console.log('question', this.question)
				}
				this.loading = false
			})
			
		},
		mounted(){
			if(!this.continue_lifecycle)
				return
			
			let that = this
			this.timer()
		},
		unmounted(){
			if(!this.continue_lifecycle)
				return
			
			clearInterval(intervalId)
			clearTimeout(timeoutID)
		},
		data() {
			return {
				continue_lifecycle: true,
				question_num: 0,
				questions: [],
				uuid: '',
				expire: new Date(),
				question: {},
				user_answers: [],
				marked: [],
			}
		},
		methods: {
			timer(){
				let time_left = Math.round((this.expire - Date.now()) / 1000)
				let minute = parseInt(time_left / 60)
				let second = time_left % 60
				let new_time = String(minute).padStart(2, '0') + ':' + String(second).padStart(2, '0')
				if(time_left > 0)
					$("#timer").text(new_time)
				else
					$("#timer").text("00:00")

				if(minute < 1 && second < 1){
					console.log('time to submit!!!')
					setTimeout(this.submit, 10, true)
					return
				}

				let current_time = Date.now() / 1000
				let A = parseInt((this.expire / 1000 - parseInt(this.expire / 1000)) * 1000)
				let B = parseInt((current_time - parseInt(current_time)) * 1000)
				if(A > B){
					timeoutID = setTimeout(this.timer, A - B, 0)
				}else if (A == B){
					timeoutID = setTimeout(this.timer, 1000, 0)
				}else{
					timeoutID = setTimeout(this.timer, A + 1000 - B, 0)
				}

			},
			goToQuestion(i) {
				this.goTo('/quiz/' + i)
			},
			clearOptions(){
				$("#A").prop("checked", false)
				$("#B").prop("checked", false)
				$("#C").prop("checked", false)
				$("#D").prop("checked", false)
			},
			selectSingle(event){
				this.clearOptions()
				var selected = event.target.value
				$("#" + selected).prop("checked", true)
				this.user_answers[this.question_num - 1] = selected
				sessionStorage.user_answers = this.user_answers
				console.log(this.user_answers)
				if(this.question_num < this.questions.length)
					this.goToQuestion(this.question_num + 1)
			},
			mark(){
				if(this.marked.includes(this.question_num)){
					this.marked.splice( this.marked.indexOf(this.question_num), 1)
					this.marked = this.marked.sort(function(a, b){return a-b})
					sessionStorage.marked = this.marked
					console.log(this.marked)
				}else{
					this.marked.push(this.question_num)
					this.marked = this.marked.sort(function(a, b){return a-b})
					sessionStorage.marked = this.marked
					console.log(this.marked)
				}
			},
			submit(timeup = false){
				if(!this.validateUser()){
					this.logout('/invalid-signin')
					return
				}
				if(!this.validateQuiz())
					return
				if(this.user_answers.includes('X')){
					if(timeup){
						alert("Time up. Your quiz will be sumitted.")
						this.submitHelper()
					}else if(window.confirm("You have not finished all the questions. Really?")) {
						this.submitHelper()
					}
				}else{
					this.submitHelper()
				}
			},
			submitHelper(){
				sessionStorage.clear()
				console.log('redirect')
				axios.post(this.BASE_URL + "quiz", JSON.stringify({
					"action": "submit",
					"data":{
						"uuid": this.uuid,
						"username": this.username,
						"questions": this.questions.join(','),
						"user_answers": this.user_answers.join(','),
						"submit_time": new Date().getTime(),
					}
				}))
				.then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] == 201){
						this.goTo('/quiz/detail/' + this.uuid)
					}
				})
			}
		}
	}
</script>