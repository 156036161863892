<template>
	
	<Loading v-if="loading" />

	<div class="vertical-center mx-10p" :hidden="loading">
        <div class="row">
            <div class="col-12 align-self-center">
                <p card-text class="display-2 text-light"><b>Learn ({{question_num + '/' + questions.length}})</b></p>
            </div>
        </div>
        <div id="content">
            <div id="question_nums" class="mt-2">
				<div class="question-nums-row" v-bind:class="{ 'd-flex' : mobile }">
					<button v-for="i in marked.length" class="btn btn-outline-light num-button" @click="goToQuestion(marked[i - 1])">{{ marked[i - 1] }}</button>
				</div>
            </div>
			
			<div class="mx-2">
                <div class="row">
                    <div class="col-12 align-self-center">
                        <p class="text-light question-stem">
                            {{question_num}}. {{question.stem}}
                        </p>
                    </div>
                </div>
				
				<div class="row">
					<div class="mx-15p text-center my-2" v-if="question.image != '' && mobile">
						<img class="question-image-mobile" v-bind:src="question.image">
					</div>
				</div>
				
				<div class="row">
                    <div class="col-10 align-self-center" v-bind:class="{ 'col-12' : question.image == '' || mobile  }">
                        <p class="text-light lead" v-bind:class="{ 'three-options' : question.D == '', 'two-options' : question.C == '' && question.D == '', 'result-correct' : question.answer == 'A' }">
                            A. {{question.A}}
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'three-options' : question.D == '', 'two-options' : question.C == '' && question.D == '', 'result-correct' : question.answer == 'B' }">
                            B. {{question.B}}
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'three-options' : question.D == '', 'd-none' : question.C == '', 'result-correct' : question.answer == 'C' }">
                            C. {{question.C}}
                        </p>
                        <p class="text-light lead" v-bind:class="{ 'd-none' : question.D == '', 'result-correct' : question.answer == 'D' }">
                            D. {{question.D}}
                        </p>
                    </div>

                    <div class="col-2 align-self-center" v-if="question.image != '' && !mobile">
                        <img class="question-image" v-bind:src="question.image">
                    </div>
				</div>

            </div>
			
			<div class="d-flex">
                <div class="col-9">
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goToQuestion(question_num - 1)" :disabled="question_num == 1">Previous</button>
                    <button class="btn btn-outline-light my-x quiz-button" @click="goToQuestion(question_num + 1)" :disabled="question_num == questions.length">Next</button>
					
					<input class="form-control page-input quiz-num-button mx-2" type="num" id="page" name="page" :placeholder="question_num" v-on:keyup.enter="togglePage()" >
					<div class="text-light lead d-inline-flex my-4"> of {{questions.length}}</div>
                </div>
                <div class="col-3 align-self-center">
                    <p class="marked-p"><a class="text-light href" v-bind:class="{ 'bi-bookmark' : !marked.includes(question_num), 'bi-bookmark-fill' : marked.includes(question_num) }" @click="mark()"></a></p>
                    <p class="marked-p mx-3"><a class="text-light href" @click="toggleLang()">{{ lang == 'en' ? 'EN' : '中' }}</a></p>
                </div>
            </div>
			
			
        </div>
    </div>

</template>

<style>
	
</style>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	export default {
		name: "Learn",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		created(){
			if(!this.continue_lifecycle)
				return
			
			if(!this.isSignin()){
				this.continue_lifecycle = false
				this.goTo('/signin')
				return
			}
			if(sessionStorage.marked == undefined || sessionStorage.questions == undefined ){
				this.continue_lifecycle = false
				this.goTo('/')
				return
			}
		
			this.question_num = parseInt(this.$route.params.num)
			this.marked = sessionStorage.marked
			this.questions = sessionStorage.questions.split(',')
			if(this.marked == ''){
				this.marked = []
			}else if(this.marked.includes(',')){
				this.marked = this.marked.split(',')
				for(let i = 0; i < this.marked.length; i ++){
					this.marked[i] = parseInt(this.marked[i])
				}
			}else{
				this.marked = [parseInt(this.marked)]
			}
			
			console.log('question_num', this.question_num)
			console.log('questions', this.questions)
			console.log('marked', this.marked)
			
			axios.post(this.BASE_URL + "question", JSON.stringify({
					"action": "get",
					"lang": this.lang,
					"data":{
						"id": this.questions[this.question_num - 1]
					}
				}))
			.then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.question = result['body']
					console.log('question', this.question)
				}
				this.loading = false
			})
			
		},
		data() {
			return {
				continue_lifecycle: true,
				question_num: 0,
				questions: [],
				question: {},
				user_answers: [],
				marked: [],
			}
		},
		methods: {
			goToQuestion(i) {
				this.goTo('/learn/' + i)
			},
			togglePage(){
				let page = $("#page").val()
				$("#page").val('')
				if(page >= 1 && page <= this.questions.length)
					this.goTo('/learn/' + page)
			},
			mark(){
				if(this.marked.includes(this.question_num)){
					this.marked.splice( this.marked.indexOf(this.question_num), 1)
					this.marked = this.marked.sort(function(a, b){return a-b})
					sessionStorage.marked = this.marked
					console.log(this.marked)
				}else{
					this.marked.push(this.question_num)
					this.marked = this.marked.sort(function(a, b){return a-b})
					sessionStorage.marked = this.marked
					console.log(this.marked)
				}
			},
		}
	}
</script>