<template>

	<Loading v-if="loading" />
	
	<div id="content-white" :hidden="loading">
		<h1 id="title" class="display-4 text-center mb-4">Password</h1>
		<form class="form-inline">
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="old_password-label" for="old_password">Old Password</label>
					<input class="form-control"  type="password" id="old_password" name="old_password" placeholder="Old Password" v-on:keyup.enter="toggleUpdate()">
					<div id="old_password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="new_password-label" for="new_password">New Password</label>
					<input class="form-control"  type="password" id="new_password" name="new_password" placeholder="New Password" v-on:keyup.enter="toggleUpdate()">
					<div id="new_password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="repeat_password-label" for="repeat_password">Repeat Password</label>
					<input class="form-control"  type="password" id="repeat_password" name="repeat_password" placeholder="Repeat Password" v-on:keyup.enter="toggleUpdate()">
					<div id="repeat_password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div align="center" class="mt-1" v-bind:class="{ 'mb-2' : !mobile }">
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="toggleUpdate()">Update</button>
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="resetInput();clearError()">Reset</button>
			</div>
		</form>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	export default {
		name: "UpdatePassword",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		data() {
			return {
				input_array: ["old_password", "new_password", "repeat_password"],
			}
		},
		mounted(){
			if(!this.isSignin()){
				this.goTo('/signin')
				return
			}
			
			this.resetInput()
			this.clearError()
			this.loading = false
		},
		methods: {
			resetInput(){
				$("#title").text(this.username)
				for(let input of this.input_array)
					$("#" + input).val('')
			},
			clearError(){
				for(let input of this.input_array){
					$("#" + input + "-label").removeClass('text-danger')
					$("#" + input + "-feedback").removeClass('d-block')
					$("#" + input + "-feedback").text('')
					$("#" + input).removeClass('invalid-input')
				}
			},
			checkPassword(password){
				if(password == "" || password == null || password == undefined)
					return [false, 'Please enter password.']
				return [true]
			},
			checkRepeatPassword(new_password, repeat_password){
				if(new_password != repeat_password)
					return [false, 'Password mismatch.']
				return [true]
			},
			async toggleUpdate() {
				this.clearError()
				
				let old_password = $("#old_password").val()
				let new_password = $("#new_password").val()
				let repeat_password = $("#repeat_password").val()
				console.log("password", old_password, new_password, repeat_password)
				
				let valid = true
				for(let input of this.input_array){
					let [result, msg] = this.checkPassword($("#" + input).val())
					if(!result){
						$("#" + input + "-label").addClass('text-danger')
						$("#" + input + "-feedback").addClass('d-block')
						$("#" + input + "-feedback").text(msg)
						$("#" + input + "").addClass('invalid-input')
						valid = false
					}
				}
				let [result, msg] = this.checkRepeatPassword(new_password, repeat_password)
				if(!result){
					$("#new_password-label").addClass('text-danger')
					$("#new_password-feedback").addClass('d-block')
					$("#new_password-feedback").text(msg)
					$("#new_password").addClass('invalid-input')
					$("#repeat_password-label").addClass('text-danger')
					$("#repeat_password-feedback").addClass('d-block')
					$("#repeat_password-feedback").text(msg)
					$("#repeat_password").addClass('invalid-input')
					valid = false
				}
				
				if(!valid)
					return
				
				axios.post(this.BASE_URL + "user", JSON.stringify({
					"action": "change_password",
					"encode": true,
					"data":{
						"username": this.username,
						"password": this.encode_password(old_password),
						"new_password": this.encode_password(new_password),
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] != 200){
						$("#repeat_password-feedback").addClass('d-block')
						$("#repeat_password-feedback").text("Update Failed")
					}else{
						this.goTo('/account')
					}
				})
			},
		}
	}
</script>