<template>
	<div class="vertical-center mx-10p">
        <div class="row">
            <div class="col-12 align-self-center text-center">
                <div class="display-2 text-light d-inline-flex"><b>Loading</b></div>
				<div class="spinner-border text-light mx-3" role="status"></div>
            </div>
		</div>
	</div>
</template>
