<template>
	<div id="content-white">
		<div id="title" class="display-6 text-center">{{dict[event]['title']}}</div>
		<div class="lead text-center my-4">{{dict[event]['msg']}}</div>
		<div class="lead text-center">Redirecting to home page in {{countdown}}</div>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	
	let timeoutID1, timeoutID2, timeoutID3 = 0

	export default {
		name: "Error",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		data() {
			return {
				countdown: 3,
				event: 'default',
				dict: {'default': {'title': 'Page not Found', 'msg': 'The page you requested does not exist'}, 'invalid-signin': {'title': 'You\'ve Logged Out', 'msg': 'Your signin is not valid, please sign in again.'}, 'invalid-quiz': {'title': 'Quiz Has Ended', 'msg': 'Quiz is not valid, please start a new one.'}}
			}
		},
		created(){
		console.log(this.$route.params.catchAll)
			if(this.$route.params.catchAll == 'invalid-quiz' || this.$route.params.catchAll == 'invalid-signin')
				this.event = this.$route.params.catchAll
			timeoutID1 = setTimeout(this.timer, 1000, 2)
			timeoutID2 = setTimeout(this.timer, 2000, 1)
			timeoutID3 = setTimeout(this.timer, 3000, 0)
		},
		unmounted(){
			clearTimeout(timeoutID1)
			clearTimeout(timeoutID2)
			clearTimeout(timeoutID3)
		},
		methods: {
			timer(new_countdown){
				this.countdown = new_countdown
				if(new_countdown == 0)
					this.goTo('/')
			},
		}
	}
</script>