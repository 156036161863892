<template>
	<div id="content-white">
		<h1 id="title" class="display-4 text-center mb-4">Sign Up</h1>
		<form class="form-inline">
			<input type="text" id="google_id" name="google_id" hidden>
			
			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label id="username-label" for="username">Username</label>
					<input class="form-control" type="text" id="username" name="username" placeholder="Username" v-on:keyup.enter="toggleSignup()">
					<div id="username-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label id="password-label" for="password">Password</label>
					<input class="form-control"  type="password" id="password" name="password" placeholder="Password" v-on:keyup.enter="toggleSignup()">
					<div id="password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label id="name-label" for="name">Full Name</label>
					<input class="form-control"  type="text" id="name" name="name" placeholder="Full Name" v-on:keyup.enter="toggleSignup()">
					<div id="name-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label id="area-label" for="area">State</label>
					<select class="form-control" id="area" name="area">
						<option hidden>State</option>
						<option v-for="area in states">{{area}}</option>
					</select>
					<div id="area-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div class="form-group row justify-content-center" v-bind:class="{ 'mx-2' : mobile }">
				<div class="col-md-5 mx-3">
					<label id="phone_number-label" for="phone_number">Phone</label>
					<input class="form-control"  type="phone" id="phone_number" name="phone_number" placeholder="Phone" v-on:keyup.enter="toggleSignup()">
					<div id="phone_number-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
				<div class="col-md-5 mx-3">
					<label id="email_address-label" for="email_address">Email</label>
					<input class="form-control"  type="email" id="email_address" name="email_address" placeholder="Email" v-on:keyup.enter="toggleSignup()">
					<div id="email_address-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>

			<div align="center" class="mt-1" v-bind:class="{ 'mb-2' : !mobile }">
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="toggleSignup()">Sign Up</button>
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="clearInput();clearError()">Clear</button>
			</div>
		</form>
	</div>
</template>

<script>
	import functions from "@/mixin/functions"
	
	const axios = require('axios')
	
	export default {
		name: "Signup",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		data() {
			return {
				input_array: ["username", "password", "name", "area", "phone_number", "email_address"],
				states: ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
			}
		},
		created(){
			if(this.isSignin()){
				this.goTo('/')
				return
			}
		},
		mounted(){
			this.clearInput()
			this.clearError()
			if(this.$route.params.third_party != ''){
				let third_party = JSON.parse(this.$route.params.third_party)
				console.log(third_party)
				console.log('google login')
				$("#name").val(third_party.name)
				$("#email_address").val(third_party.email_address)
				$("#google_id").val(third_party.google_id)
			}
		},
		methods: {
			clearInput(){
				for(let input of this.input_array)
					$("#" + input).val('')
				$("#area").val('State')
				$("#google_id").val('')
			},
			clearError(){
				for(let input of this.input_array){
					$("#" + input + "-label").removeClass('text-danger')
					$("#" + input + "-feedback").removeClass('d-block')
					$("#" + input + "-feedback").text('')
					$("#" + input).removeClass('invalid-input')
				}
			},
			checkUsername(username){
				if(username == "" || username == null || username == undefined)
					return [false, 'Please enter username.']
				return [true]
			},
			checkPassword(password){
				if(password == "" || password == null || password == undefined)
					return [false, 'Please enter password.']
				return [true]
			},
			checkName(name){
				if(name == "" || name == null || name == undefined)
					return [false, 'Please enter name.']
				return [true]
			},
			checkState(area){
				if(!this.states.includes(area))
					return [false, 'Please select state.']
				return [true]
			},
			checkPhone(phone_number){
				if(phone_number == "" || phone_number == null || phone_number == undefined)
					return [false, 'Please enter phone number.']
				return [true]
			},
			checkEmail(email_address){
				if(email_address == "" || email_address == null || email_address == undefined)
					return [false, 'Please enter email address.']
				if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email_address)){
					return [true]
				}
				return [false, "Invalid email address."]
			},
			checkInput(input, value){
				if(input == 'username')
					return this.checkUsername(value)
				if(input == 'password')
					return this.checkPassword(value)
				if(input == 'name')
					return this.checkName(value)
				if(input == 'phone_number')
					return this.checkPhone(value)
				if(input == 'email_address')
					return this.checkEmail(value)
				if(input == 'area')
					return this.checkState(value)
			},
			setError(input, msg){
				$("#" + input + "-label").addClass('text-danger')
				$("#" + input + "-feedback").addClass('d-block')
				$("#" + input + "-feedback").text(msg)
				$("#" + input + "").addClass('invalid-input')
			},
			async toggleSignup() {
				this.clearError()
				
				let google_id = $("#google_id").val()
				let username = $("#username").val()
				let password = $("#password").val()
				let name = $("#name").val()
				let phone_number = $("#phone_number").val()
				let email_address = $("#email_address").val()
				let area = $("#area").val()
				console.log("signup", google_id, username, password, name, phone_number, email_address, area)
				
				let valid = true
				for(let input of this.input_array){
					let [result, msg] = this.checkInput(input, $("#" + input).val())
					if(!result){
						this.setError(input, msg)
						valid = false
					}
				}
				
				if(!valid)
					return
				
				axios.post(this.BASE_URL + "user", JSON.stringify({
					"action": "create",
					"encode": true,
					"data":{
						"google_id": google_id,
						"username": username,
						"password": this.encode_password(password),
						"name": name,
						"phone_number": phone_number,
						"email_address": email_address,
						"area": area,
						"avatar": ""
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] == 201){
						this.loginUser(username, email_address)
						this.goTo('/')
					}else{
						this.setError('username', result['body'])
					}
				})
			},
		}
	}
</script>