<template>
	<div id="content-white">
		<h1 id="title" class="display-4 text-center mb-4">Sign In</h1>
		<form class="form-inline">
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="username-label" for="username">Username</label>
					<input class="form-control" type="text" id="username" name="username" placeholder="Username" v-on:keyup.enter="toggleLogin()">
					<div id="username-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div class="form-group row justify-content-center" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}">
				<div class="col-md-12">
					<label id="password-label" for="password">Password</label>
					<input class="form-control"  type="password" id="password" name="password" placeholder="Password" v-on:keyup.enter="toggleLogin()">
					<div id="password-feedback" class="invalid-feedback" v-bind:class="{ 'mb-2' : !mobile }"></div>
				</div>
			</div>
			
			<div align="center" class="mt-1" v-bind:class="{ 'mb-2' : !mobile }">
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="toggleLogin()">Sign In</button>
				<button type="button" class="ls-button btn btn-outline-secondary mt-2" v-bind:class="{'mx-5' : !mobile, 'mx-2' : mobile}" @click="goTo('/signup')">Sign Up</button>
			</div>
			
			<div align="center" class="href href-link mt-4" v-bind:class="{ 'mb-2' : !mobile }" @click="goTo('resetpassword')">
				Forget Password?
			</div>

			<div align="center" class="mt-3">
				<GoogleLogin :callback="googleResponse" />
			</div>
		</form>
	</div>
</template>


<script>
	import functions from "@/mixin/functions"
	
	const axios = require('axios')
	import jwt_decode from "jwt-decode";
	
	export default {
		name: "Signin",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		data() {
			return {
				input_array: ["username", "password"],
			}
		},
		created(){
			if(this.isSignin()){
				this.goTo('/')
				return
			}
		},
		mounted(){
			this.clearError()
			this.clearInput()
		},
		methods: {
            googleLogin(id, name, email_address, avatar) {
				axios.post(this.BASE_URL + "user", JSON.stringify({
					"action": "google_oauth",
					"encode": true,
					"data":{
						"google_id": id,
						"name": name,
						"email_address": email_address,
						"avatar": avatar
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] == 200){
						let username = result['body']['username']
						let email_address = result['body']['email_address']
						this.loginUser(username, email_address)
						this.goTo('/')
					}else{
						let info = {'google_id': id, 'name': name, 'email_address': email_address, 'avatar': 'avatar'}
						this.$router.push({name: 'Signup', params: {third_party: JSON.stringify(info)}})
					}
				})
            },
            googleResponse(response) {
                // decodeJwtResponse() is a custom function defined by you
                // to decode the credential response.
                const responsePayload = jwt_decode(response.credential);
				this.googleLogin(responsePayload.sub, responsePayload.name, responsePayload.email, responsePayload.picture)
                console.log("ID: " + responsePayload.sub);
                console.log('Full Name: ' + responsePayload.name);
                console.log('Given Name: ' + responsePayload.given_name);
                console.log('Family Name: ' + responsePayload.family_name);
                console.log("Image URL: " + responsePayload.picture);
                console.log("Email: " + responsePayload.email);
            },
			clearInput(){
				for(let input of this.input_array)
					$("#" + input).val('')
			},
			clearError(){
				for(let input of this.input_array){
					$("#" + input + "-label").removeClass('text-danger')
					$("#" + input + "-feedback").removeClass('d-block')
					$("#" + input + "-feedback").text('')
					$("#" + input).removeClass('invalid-input')
				}
			},
			checkUsername(username){
				if(username == "" || username == null || username == undefined)
					return [false, 'Please enter username.']
				return [true]
			},
			checkPassword(password){
				if(password == "" || password == null || password == undefined)
					return [false, 'Please enter password.']
				return [true]
			},
			checkInput(input, value){
				if(input == 'username')
					return this.checkUsername(value)
				if(input == 'password')
					return this.checkPassword(value)
			},
			async toggleLogin() {
				this.clearError()
				
				let username = $("#username").val()
				let password = $("#password").val()
				console.log("signin", username, password)
				
				let valid = true
				for(let input of this.input_array){
					let [result, msg] = this.checkInput(input, $("#" + input).val())
					if(!result){
						$("#" + input + "-label").addClass('text-danger')
						$("#" + input + "-feedback").addClass('d-block')
						$("#" + input + "-feedback").text(msg)
						$("#" + input + "").addClass('invalid-input')
						valid = false
					}
				}
				
				if(!valid)
					return
				
				axios.post(this.BASE_URL + "user", JSON.stringify({
					"action": "login",
					"encode": true,
					"data":{
						"username": username,
						"password": this.encode_password(password)
					}
				})).then((response) => {
					return response.data
				}).then((result) => {
					console.log('result', result)
					if(result['statusCode'] == 200){
						let email_address = result['body']['email_address']
						this.loginUser(username, email_address)
						this.goTo('/')
					}else{
						console.log('signin failed')
						$("#password-feedback").addClass('d-block')
						$("#password-feedback").text(result['body'])
					}
				})
			},
		}
	}
</script>