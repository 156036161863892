<template>

	<Loading v-if="loading" />
	
	<div class="vertical-center mx-10p" :hidden="loading">
        <div class="row">
            <div class="col-9 align-self-center">
                <p card-text class="display-2 text-light"><b>Quiz History</b></p>
            </div>
        </div>
        <div id="content">
			<div class="mx-2">
                <div class="row">
                    <div class="col-2 align-self-center text-center" >
                        <p class="text-light my-3 question-stem">
                            No.
                        </p>
                    </div>
                    <div class="col-3 align-self-center text-center" >
                        <p class="text-light my-3 question-stem">
                            Score
                        </p>
                    </div>
                    <div class="col-4 align-self-center text-center" >
                        <p class="text-light my-3 question-stem">
                            Submit Time
                        </p>
                    </div>
                    <div class="col-3 align-self-center text-center" >
                        <p class="text-light my-3 question-stem">
                            Action
                        </p>
                    </div>
                </div>
				
				<div style="height: 1px; width: 95%; background-color: white; margin: auto; margin-bottom: 0.5rem"></div>
				
				<div class="row my-2" v-for="(item, index) in quiz_history">
                    <div class="col-2 align-self-center text-center">
                        <p class="text-light lead">
                            {{(page - 1) * 5 + index + 1}}
                        </p>
                    </div>
                    <div class="col-3 align-self-center text-center">
                        <p class="text-light lead">
                            {{item['score']}}
                        </p>
                    </div>
                    <div class="col-4 align-self-center text-center">
                        <p class="text-light lead">
                            {{new Date(parseInt(item['submit_time'])).toLocaleString('en-US', { hourCycle: 'h23' })}}
                        </p>
                    </div>
                    <div class="col-3 align-self-center text-center">
                        <p class="text-light lead quiz-detail-button" @click="goTo('/quiz/detail/' + item['uuid'])">
                            Detail
						</p>
                    </div>
				</div>

            </div>
			
			<div class="d-flex">
                <div class="col-12">
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goTo('/quiz/history/' + (page - 1))" :disabled="page == 1">Previous</button>
					<p class="text-light h5 mx-2 d-inline">{{start_num}} - {{end_num}}</p>
                    <button class="btn btn-outline-light my-4 quiz-button" @click="goTo('/quiz/history/' + (page + 1))" :disabled="page * 5 >= quiz_count">Next</button>
                </div>
            </div>
			
			
        </div>
    </div>

</template>

<style>
	
</style>

<script>
	import functions from "@/mixin/functions"
	import Loading from "@/components/Loading.vue"
	
	const axios = require('axios')
	
	export default {
		name: "QuizHistory",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		components: {
			Loading,
		},
		created(){
			if(!this.isSignin()){
				this.goTo('/signin')
				return
			}
		
			this.page = parseInt(this.$route.params.page)
			
			console.log('page', this.page)
			
			axios.post(this.BASE_URL + "quiz", JSON.stringify({
					"action": "get_by_username",
					"page": this.page,
					"data":{
						"username": this.username
					}
				}))
			.then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					this.quiz_history = result['body']['quiz_history']
					this.quiz_count = result['body']['quiz_count']
					this.start_num = (this.page - 1) * 5 + 1
					this.end_num = (this.page - 1) * 5 + this.quiz_history.length
					if(this.start_num > this.end_num){
						if(this.page == 1){
							this.start_num = this.end_num
						}else{
							let max_page = Math.ceil(this.quiz_count / 5)
							this.goTo('/quiz/history/' + max_page)
						}
					}
					console.log('quiz_history', this.quiz_history)
				}
				this.loading = false
			})
			
		},
		data() {
			return {
				page: 1,
				quiz_count: 0,
				quiz_history: [],
				start_num: 0,
				end_num: 0,
			}
		},
	}
</script>