const axios = require('axios')
import { Buffer } from 'buffer';

export default {
	data() {
		return {
			BASE_URL: "https://api.usdriving.us/",
			QUESTION_LIMIT: 50,
			TIME_LIMIT: 60,
			username: "",
			email_address: "",
			questions: [],
			lang: "",
			loading: true,
			mobile: false,
		}
	},
	created(){
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
			this.mobile = true
		
		this.username = this.getCookie('username')
		this.email_address = this.getCookie('email_address')
		if(this.getCookie('lang') == null)
			this.setCookie('lang', 'en')
		this.lang = this.getCookie('lang')

		if(process.env.NODE_ENV != 'development')
			console.log = () => { }
		
		let title = this.$options.name
		if(title == '' || title == undefined || title == null || title == 'Navbar' || title == 'Home')
			title = 'US Driving'
		else
			title += ' | US Driving'
		$(document).attr("title", title);
	},
	methods: {
		refresh(){
			this.$emit('incrementKey')
		},
		goTo(path){
			if(path == this.$route.path)
				this.refresh()
			else
				this.$router.push(path)
		},
		uuidv4() {
			return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
				(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
			)
		},
		encode_password(password){
			let encoded_pasword = Buffer.from(password).toString('base64')
			return Buffer.from(encoded_pasword).toString('base64')
		},
		getSession(key) {
			return sessionStorage.getItem(key)
		},
		setSession(key, value) {
			sessionStorage.setItem(key, value)
		},
		deleteSession(key) {
			sessionStorage.removeItem(key)
		},
		getCookie(key) {
			let name = key + '='
			let decodedCookie = decodeURIComponent(document.cookie)
			let ca = decodedCookie.split(';')
			for(let i = 0; i <ca.length; i++) {
				let c = ca[i]
				while (c.charAt(0) == ' ') {
					c = c.substring(1)
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length)
				}
			}
			return null
		},
		setCookie(key, value){
			const d = new Date()
			d.setTime(d.getTime() + (30*24*60*60*1000))
			let expires = 'expires=' + d.toUTCString()
			document.cookie = key + '=' + value + ';' + expires + ';path=/'
		},
		deleteCookie(key){
			document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
		},
		toggleLang() {
			if(this.lang == "en")
				this.lang = "zh"
			else
				this.lang = "en"
			this.setCookie('lang', this.lang)
			this.refresh()
		},
		loginUser(username, email_address){
			this.setCookie('username', username)
			this.setCookie('email_address', email_address)
			this.setCookie('token', this.getUserToken(username, email_address))
		},
		logout(path='/'){
			this.deleteCookie("username")
			this.deleteCookie("email_address")
			this.deleteCookie("token")
			sessionStorage.clear()
			this.goTo(path)
		},
		isSignin(){
			if(this.getCookie("username") !== null){
				return true
			}
			return false
		},
		getQuizToken(uuid, expire, questions){
			return CryptoJS.SHA256(uuid + expire + questions).toString()
		},
		getUserToken(username, email_address){
			return CryptoJS.SHA256((username + email_address).toString().split('').reverse().join('')).toString()
		},
		startQuiz: async function (category, refresh = true) {
			axios.post(this.BASE_URL + "question", JSON.stringify({
				"action": "category_question",
				"data":{
					"category": category,
					"random_order": true,
					"limit": this.QUESTION_LIMIT
				}
			})).then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					console.log('result[body]', result['body'])
					sessionStorage.questions = result['body']
					sessionStorage.uuid = this.uuidv4()
					sessionStorage.expire = new Date().getTime() + (this.TIME_LIMIT*60*1000)
					sessionStorage.user_answers = Array(this.QUESTION_LIMIT).fill('X')
					sessionStorage.marked = []
					sessionStorage.token = this.getQuizToken(sessionStorage.uuid, sessionStorage.expire, sessionStorage.questions)
					this.marked = []
					this.goTo('/quiz/1')
				}
			})
		},
		startLearn: async function (category, random_order = false) {
			axios.post(this.BASE_URL + "question", JSON.stringify({
				"action": "category_question",
				"data":{
					"category": category,
					"random_order": random_order,
				}
			})).then((response) => {
				return response.data
			}).then((result) => {
				console.log('result', result)
				if(result['statusCode'] == 200){
					console.log('result[body]', result['body'])
					sessionStorage.questions = result['body']
					sessionStorage.marked = []
					this.marked = []
					this.goTo('/learn/1')
				}
			})
		},
		isSignin(){
			if(this.getCookie("username") !== null && this.getCookie("email_address") !== null && this.getCookie("token") !== null){
				return true
			}
			return false
		},
		switchLang(){
			if(this.lang == "en")
				this.lang = "zh"
			else
				this.lang = "en"
		},
		validateQuiz(){
			if(sessionStorage.token != this.getQuizToken(sessionStorage.uuid, sessionStorage.expire, sessionStorage.questions)){
				sessionStorage.clear()
				this.goTo('/invalid-quiz')
				return false
			}
			return true
		},
		validateUser(){
			if(!this.isSignin())
				return true
			else
				return this.getCookie('token') == this.getUserToken(this.getCookie('username'), this.getCookie('email_address'))
		}
		
	}
};