<template>
    <div class="vertical-center text-center mx-10p">
        <img class="href mode-image" src="@/assets/images/learn.png" @click="goTo('/category')">
        <img class="href mode-image" src="@/assets/images/quiz.png" @click="startQuiz('All')">
    </div>
</template>

<script>
	import functions from "@/mixin/functions"
	
	export default {
		name: "Mode",
		props: ["refresh_key"],
		emits: ["incrementKey"],
		mixins: [functions],
		created(){
			if(!this.isSignin()){
				this.goTo('/signin')
				return
			}
		},
	}
</script>